Vue.component('modal', {
    props: ['component', 'label', 'headerText', 'icon'],
    data: function () {
        return {
            visible: false
        }
    },
    template: '' +
    '<div>' +
    '   <div class="expanded button small show-modal-btn" @click="show">' +
    '   <i v-if="icon" v-bind:class="icon" aria-hidden="true"></i> {{label}}' +
    '   </div>' +
    '   <div v-if="visible">' +
    '       <transition name="modal">' +
    '          <div class="modal-mask">' +
    '              <div class="modal-wrapper">' +
    '                  <div class="modal-container">' +
    '                      <div class="modal-header">' +
    '                           <h4>{{ headerText }}</h4>' +
    '                           <button class="close-button" aria-label="Close alert" type="button" @click="hide">' +
    '                               <span aria-hidden="true">&times;</span>' +
    '                           </button>' +
    '                       </div>' +
    '                      <div class="modal-body"><slot name="body"></slot></div>' +
    '                  </div>' +
    '              </div>' +
    '          </div>' +
    '       </transition>' +
    '   </div>' +
    '</div>',
    methods: {
        show: function (event) {
            this.visible = true;
        },
        hide: function (event) {
            this.visible = false;
        }
    }
});
