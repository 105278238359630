Vue.component('inline-messages', {
    props: ['messages', 'alertType'],
    template: '' +
    '<div v-if="messages">' +
    '   <ul :class="alertType">' +
    '       <li v-for="item in messages">{{ item }}</li>' +
    '   </ul>' +
    '</div>'
});

Vue.component('form-component', {
    props: ['form_name'],
    template: '<div class="form-component-wrapper"></div>',
    render: function (createElement) {
        Vue.http.options.emulateJSON = true;

        var self = this;
        var buildUrl = Routing.generate('form.build', {formName: this.form_name});

        this.$http.post(buildUrl, {emulateHTTP: true})
            .then(function (response) {
                var formComponent = Vue.extend({
                    template: response.body.form,
                    data: function () {
                        return {
                            entry: {},
                            errors: {},
                            messages: [],
                            form_name: self.form_name
                        }
                    },
                    methods:{
                        processForm: function (event) {
                            var formData = new FormData(self.$el.querySelector('form'));
                            var url = Routing.generate('form.process', {formName: self.form_name});

                            this.$http.post(url, formData)
                                .then((response) => {
                                    this.errors = {};
                                    this.entry = {};
                                    this.messages = [response.body.message];
                                })
                                .catch((response) => {
                                    this.messages = [];
                                    this.errors = {};
                                    var errors = response.body.errors;

                                    for (var key in errors) {
                                        if (!errors.hasOwnProperty(key)) {
                                            continue;
                                        }
                                        Vue.set(this.errors, key, errors[key]);
                                    }
                                });
                        }
                    }
                });

                self.$el.innerHTML = '';
                self.$el.appendChild(new formComponent().$mount().$el);

                $('body').trigger('vue-modal-form.show');
            });

        return createElement('div', 'Loading...');
    }
});